var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "i-layout-header-trigger i-layout-header-trigger-min i-layout-header-trigger-in"
  }, [_c("Notification", {
    staticClass: "i-layout-header-notice",
    class: {
      "i-layout-header-notice-mobile": _vm.isMobile
    },
    attrs: {
      wide: _vm.isMobile,
      "badge-props": _vm.badgeProps,
      count: _vm.tabcount.count1,
      locale: {
        loadedAll: "",
        loading: "加载中...",
        loadMore: "加载更多",
        clear: "清空"
      }
    },
    on: {
      "on-load-more": _vm.handleLoadMore,
      "on-item-click": _vm.onItemClick
    }
  }, [_c("Icon", {
    attrs: {
      slot: "icon",
      custom: "i-icon i-icon-notification"
    },
    slot: "icon"
  }), _c("NotificationTab", {
    attrs: {
      title: "未读",
      name: "1",
      "show-clear": false,
      count: _vm.tabcount.count1,
      "show-loaded-all": false,
      "loaded-all": false
    }
  }, _vm._l(_vm.messageList1, function (item, index) {
    return _c("NotificationItem", {
      key: index,
      attrs: {
        title: item.title,
        icon: item.icon,
        "icon-color": item.iconColor,
        time: item.time,
        read: item.read,
        index: index,
        content: item.content
      }
    });
  }), 1), _c("NotificationTab", {
    attrs: {
      title: "已读",
      name: "2",
      "show-clear": false,
      "show-loaded-all": false,
      "loaded-all": false,
      count: _vm.tabcount.count2
    }
  }, _vm._l(_vm.messageList2, function (item, index) {
    return _c("NotificationItem", {
      key: index,
      attrs: {
        title: item.title,
        icon: item.icon,
        "icon-color": item.iconColor,
        time: item.time,
        read: item.read,
        index: index,
        content: item.content
      }
    });
  }), 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };