import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapState } from 'vuex';
import { innermailList, innermailInfo, innermailRead } from '@/api/user';
export default {
  name: 'iHeaderNotice',
  data: function data() {
    return {
      badgeProps: {
        offset: [20, 0]
      },
      messageList1: [],
      messageList2: [],
      tabcount: {
        count1: 0,
        count2: 0
      },
      header1: {
        page: 1,
        pageSize: 10
      },
      header2: {
        page: 1,
        pageSize: 10
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/layout', ['isMobile'])), mapState('admin/user', ['info'])),
  methods: {
    getData: function getData(type) {
      // 1 未读 2 已读
      var that = this;
      var parms = {
        "header": that['header' + type],
        "to": that.info.userName,
        "status": type - 1,
        "fav": 2,
        "isComplated": 0
      };
      innermailList(parms).then(function (data) {
        if (data.header.code === 200) {
          that.tabcount['count' + type] = data.header.count;
          for (var i in data.items) {
            var d = data.items[i];
            that['messageList' + type].push({
              title: d.title,
              read: d.status === 1,
              id: d.messageId,
              url: d.url,
              progress: d.progress,
              // 进度 100完成
              time: d.createTime,
              status: d.status,
              content: d.content,
              messageCaseId: d.messageCaseId,
              icon: d.progress === 100 ? "ios-cloud-done" : "ios-clock",
              iconColor: d.status === 0 ? "#ff9900" : "#808695"
            });
          }
        }
      });
    },
    handleLoadMore: function handleLoadMore(s) {
      var type = s.name;
      if (this.tabcount['count' + type] > this['messageList' + type].length) {
        this['header' + type].page++;
        this.getData(type);
      } else {
        this.$Message.success('已全部加载完毕');
      }
    },
    onItemClick: function onItemClick(tab, index) {
      var _this = this;
      var type = tab.name; // 1未读 2 已读
      var item = this['messageList' + type][index.index];
      innermailInfo({
        messageCaseId: item.messageCaseId
      }).then(function (res) {
        if (res.header.code === 200 && res.progress === 100) {
          _this.$Modal.info({
            width: '900px',
            title: '下载内容',
            content: "数据导出任务 <br />创建时间：" + item.time + "<br />" + res.content
          });
        } else {
          _this.$Message.error('任务尚未执行，预计在今天22:00后执行');
        }
      });
      if (type === '1') {
        item.status = 1;
        item.iconColor = '#808695';
        innermailRead({
          messageCaseId: item.messageCaseId,
          status: 1
        });
        this['messageList1'].splice(index.index, 1);
        this['messageList2'].push(item);
        this.tabcount.count1--;
        this.tabcount.count2++;
      }
    }
  },
  mounted: function mounted() {
    this.getData(1);
    this.getData(2);
  }
};