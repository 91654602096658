// 基本档接口
import request from '@/plugins/request';
var proxy = process.env.VUE_APP_API + '/mng';
var proxy2 = process.env.VUE_APP_API;

// ====== 角色 ======
// 查询角色列表
export function QueryRolesList(data) {
  return request({
    url: proxy + '/api/RolesContoller/QueryRolesList',
    method: 'post',
    data: data
  });
}

// 查询角色详情
export function QueryRolesInfo(data) {
  return request({
    url: proxy + '/api/RolesContoller/QueryRolesInfo',
    method: 'post',
    data: data
  });
}

// 保存角色
export function SaveRoles(data) {
  return request({
    url: proxy + '/api/RolesContoller/SaveRoles',
    method: 'post',
    data: data
  });
}

// 修改角色状态
export function RolesContollerChangeStatus(data) {
  return request({
    url: proxy + '/api/RolesContoller/ChangeStatus',
    method: 'post',
    data: data
  });
}

// ====== 员工 ======
// 查询员工列表
export function QueryUserList(data) {
  return request({
    url: proxy + '/api/UserContoller/QueryUserList',
    method: 'post',
    data: data
  });
}

// 查询员工详情
export function QueryUserInfo(data) {
  return request({
    url: proxy + '/api/UserContoller/QueryUserInfo',
    method: 'post',
    data: data
  });
}

// 保存员工
export function SaveUser(data) {
  return request({
    url: proxy + '/api/UserContoller/SaveUser',
    method: 'post',
    data: data
  });
}

// 修改员工状态
export function UserContollerChangeStatus(data) {
  return request({
    url: proxy + '/api/UserContoller/ChangeStatus',
    method: 'post',
    data: data
  });
}

// 重置密码
export function ResetPwd(data) {
  return request({
    url: proxy + '/api/UserContoller/ResetPwd',
    method: 'post',
    data: data
  });
}

// ====== 权限 ======
// 查询权限列表
export function QueryPermissionsList(data) {
  return request({
    url: proxy + '/api/PermissionsContoller/QueryPermissionsList',
    method: 'post',
    data: data
  });
}

// 查询权限详情
export function QueryPermissionsInfo(data) {
  return request({
    url: proxy + '/api/PermissionsContoller/QueryPermissionsInfo',
    method: 'post',
    data: data
  });
}

// 保存权限
export function SavePermissions(data) {
  return request({
    url: proxy + '/api/PermissionsContoller/SavePermissions',
    method: 'post',
    data: data
  });
}

// 保存权限
export function QueryPermissionsTerminalList(data) {
  return request({
    url: proxy + '/api/PermissionsContoller/QueryPermissionsTerminalList',
    method: 'post',
    data: data
  });
}

// 站内信列表
export function innermailList(data) {
  return request({
    url: proxy2 + '/power/v2/innermail/list',
    method: 'post',
    data: data
  });
}
// 站内信详情
export function innermailInfo(data) {
  return request({
    url: proxy2 + '/power/v2/innermail/info',
    method: 'post',
    data: data
  });
}
// 站内信已读
export function innermailRead(data) {
  return request({
    url: proxy2 + '/power/v2/innermail/read',
    method: 'post',
    data: data
  });
}