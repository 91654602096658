/*
 * @Description: axios.CancelToken
 * @Author: rhd
 * @Date: 2025-03-17 18:04:32
 * @LastEditors: rhd
 * @LastEditTime: 2025-03-17 18:05:42
 */
var store = {
  _axiosPromiseCancel: []
};
export default store;