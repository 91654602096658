import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_PROD_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'marketing-center';
var pre = 'evaluate';
var meta = {
  auth: true,
  closable: true,
  showMenu: false
};
export default [{
  path: "/".concat(basePre, "/gift-alert"),
  title: '弹屏有礼列表',
  name: "gift-alert",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '弹屏有礼'
  }),
  component: "pages/".concat(basePre, "/gift-alert/index.vue")
}, {
  path: "/".concat(basePre, "/gift-integral"),
  title: '消费返积分列表',
  name: "gift-integral",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '消费返积分'
  }),
  component: "pages/".concat(basePre, "/gift-integral/index.vue")
}, {
  path: "/".concat(basePre, "/gift-list"),
  title: '活动列表',
  name: "gift-list",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '活动列表'
  }),
  component: "pages/".concat(basePre, "/gift-list/index.vue")
}, {
  path: "/".concat(basePre, "/gift-order-lukyDraw"),
  title: '下单抽抽乐列表',
  name: "gift-order-lukyDraw",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '下单抽抽乐'
  }),
  component: "pages/".concat(basePre, "/gift-order-lukyDraw/index.vue")
}, {
  path: "/".concat(basePre, "/gift-exchange"),
  title: '积分兑换列表',
  name: "gift-exchange",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '积分兑换'
  }),
  component: "pages/".concat(basePre, "/gift-exchange/index.vue")
}, {
  path: "/".concat(basePre, "/gift-consumption"),
  title: '消费送礼列表',
  name: "gift-consumption",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '消费送礼'
  }),
  component: "pages/".concat(basePre, "/gift-consumption/index.vue")
}, {
  path: "/".concat(basePre, "/gift-sign"),
  title: '签到有礼列表',
  name: "gift-sign",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '签到有礼'
  }),
  component: "pages/".concat(basePre, "/gift-sign/index.vue")
}, {
  path: "/".concat(basePre, "/gift-password"),
  title: '口令领券列表',
  name: "gift-password",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '口令领券'
  }),
  component: "pages/".concat(basePre, "/gift-password/index.vue")
}, {
  path: "/".concat(basePre, "/gift-getfree"),
  title: '免费领取列表',
  name: "gift-getfree",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '免费领取'
  }),
  component: "pages/".concat(basePre, "/gift-getfree/index.vue")
}, {
  path: "/".concat(basePre, "/gift-raffle"),
  title: '摇奖活动列表',
  name: "gift-raffle",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '摇奖活动'
  }),
  component: "pages/".concat(basePre, "/gift-raffle/index.vue")
}, {
  path: "/".concat(basePre, "/gift-gather"),
  title: '集点活动列表',
  name: "gift-gather",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '集点活动'
  }),
  component: "pages/".concat(basePre, "/gift-gather/index.vue")
}, {
  path: "/".concat(basePre, "/gift-recommend"),
  title: '推荐有礼列表',
  name: "gift-recommend",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '推荐有礼'
  }),
  component: "pages/".concat(basePre, "/gift-recommend/index.vue")
}, {
  path: "/".concat(basePre, "/gift-attention"),
  title: '关注送礼列表',
  name: "gift-attention",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '关注送礼'
  }),
  component: "pages/".concat(basePre, "/gift-attention/index.vue")
}, {
  path: "/".concat(basePre, "/gift-upgrade"),
  title: '升级有礼列表',
  name: "gift-upgrade",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '升级有礼'
  }),
  component: "pages/".concat(basePre, "/gift-upgrade/index.vue")
}, {
  path: "/".concat(basePre, "/gift-birthday"),
  title: '生日赠送列表',
  name: "gift-birthday",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '生日赠送'
  }),
  component: "pages/".concat(basePre, "/gift-birthday/index.vue")
}, {
  path: "/".concat(basePre, "/gift-perfect"),
  title: '完善资料送礼列表',
  name: "gift-perfect",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '完善资料送礼'
  }),
  component: "pages/".concat(basePre, "/gift-perfect/index.vue")
}, {
  path: "/".concat(basePre, "/gift-awaken"),
  title: '唤醒有礼列表',
  name: "gift-awaken",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '唤醒有礼'
  }),
  component: "pages/".concat(basePre, "/gift-awaken/index.vue")
}, {
  path: "/".concat(basePre, "/gift-share"),
  title: '分享裂变列表',
  name: "gift-share",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '分享裂变'
  }),
  component: "pages/".concat(basePre, "/gift-share/index.vue")
}, {
  path: "/".concat(basePre, "/gift-register"),
  title: '开卡赠送列表',
  name: "gift-register",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '开卡赠送'
  }),
  component: "pages/".concat(basePre, "/gift-register/index.vue")
}, {
  path: "/".concat(basePre, "/gift-mass-sent"),
  title: '群发礼品列表',
  name: "gift-mass-sent",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '群发礼品'
  }),
  component: "pages/".concat(basePre, "/gift-mass-sent/index.vue")
}, {
  path: "/".concat(basePre, "/evaluate-courtesy"),
  title: '评价有礼列表',
  name: "evaluate-courtesy",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/gift-index"),
    title: '评价有礼'
  }),
  component: "pages/".concat(basePre, "/evaluate/evaluate-courtesy/index.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "-courtesy-detail"),
  title: '评价有礼模板详情',
  name: "".concat(pre, "-courtesy-evaluate-detail"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '评价有礼模板详情'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/").concat(pre, "-courtesy/courtesy-detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "-courtesy-list"),
  title: '交易评价列表',
  name: "".concat(pre, "-courtesy-evaluate-list"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "-courtesy"),
    title: '交易评价列表'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/").concat(pre, "-courtesy/courtesy-list.vue")
}, {
  path: "/".concat(basePre, "/gift-alert-detail/:activityType/:type/:id?"),
  title: '弹屏有礼详情',
  name: "gift-alert-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '弹屏有礼'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-recommend-detail/:activityType/:type/:id?"),
  title: '推荐有礼详情',
  name: "gift-recommend-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '推荐有礼'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-order-lukyDraw-detail/:activityType/:type/:id?"),
  title: '下单抽抽乐详情',
  name: "gift-order-lukyDraw-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '下单抽抽乐'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-register-detail/:activityType/:type/:id?"),
  title: '开卡赠送详情',
  name: "gift-register-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '开卡赠送'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-exchange-detail/:activityType/:type/:id?"),
  title: '积分兑换详情',
  name: "gift-exchange-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '积分兑换'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-share-detail/:activityType/:type/:id?"),
  title: '分享裂变详情',
  name: "gift-share-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '分享裂变'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-password-detail/:activityType/:type/:id?"),
  title: '口令领券详情',
  name: "gift-password-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '口令领券'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-sign-detail/:activityType/:type/:id?"),
  title: '签到有礼详情',
  name: "gift-sign-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '签到有礼'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-consumption-detail/:activityType/:type/:id?"),
  title: '消费送礼详情',
  name: "gift-consumption-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '消费送礼'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-getfree-detail/:activityType/:type/:id?"),
  title: '免费领取详情',
  name: "gift-getfree-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '免费领取'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-integral-detail/:activityType/:type/:id?"),
  title: '消费返积分详情',
  name: "gift-integral-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '消费返积分'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-attention-detail/:activityType/:type/:id?"),
  title: '关注送礼详情',
  name: "gift-attention-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '关注送礼'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-raffle-detail/:activityType/:type/:id?"),
  title: '摇奖活动详情',
  name: "gift-raffle-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '摇奖活动'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-perfect-detail/:activityType/:type/:id?"),
  title: '完善资料送礼详情',
  name: "gift-perfect-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '完善资料送礼'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-birthday-detail/:activityType/:type/:id?"),
  title: '生日赠送详情',
  name: "gift-birthday-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '生日赠送'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-awaken-detail/:activityType/:type/:id?"),
  title: '唤醒有礼详情',
  name: "gift-awaken-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '唤醒有礼'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-upgrade-detail/:activityType/:type/:id?"),
  title: '升级有礼详情',
  name: "gift-upgrade-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '升级有礼'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-mass-sent-detail/:activityType/:type/:id?"),
  title: '群发礼品详情',
  name: "gift-mass-sent-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '群发礼品'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-gather-detail/:activityType/:type/:id?"),
  title: '集点活动详情',
  name: "gift-gather-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '集点活动'
  }),
  component: "pages/".concat(basePre, "/gift-detail/detail.vue")
}, {
  path: "/".concat(basePre, "/gift-alert-colorful-detail/:style/:id"),
  title: '弹屏有礼装修',
  name: "gift-alert-colorful-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '弹屏有礼装修详情'
  }),
  component: "pages/".concat(basePre, "/gift-alert/colorful-detail.vue")
}, {
  path: "/".concat(basePre, "/gift-password-colorful-detail/:style/:id"),
  title: '口令领券装修',
  name: "gift-password-colorful-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '口令领券装修详情'
  }),
  component: "pages/".concat(basePre, "/gift-password/colorful-detail.vue")
}, {
  path: "/".concat(basePre, "/gift-sign-colorful-detail/:style/:id"),
  title: '签到有礼装修',
  name: "gift-sign-colorful-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '签到有礼装修详情'
  }),
  component: "pages/".concat(basePre, "/gift-sign/colorful-detail.vue")
}, {
  path: "/".concat(basePre, "/gift-consumption-colorful-detail/:style/:id"),
  title: '消费送礼装修',
  name: "gift-consumption-colorful-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '消费送礼装修详情'
  }),
  component: "pages/".concat(basePre, "/gift-consumption/colorful-detail.vue")
}, {
  path: "/".concat(basePre, "/gift-getfree-colorful-detail/:style/:id"),
  title: '免费领取装修',
  name: "gift-getfree-colorful-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/gift-index"),
    title: '免费领取装修详情'
  }),
  component: "pages/".concat(basePre, "/gift-getfree/colorful-detail.vue")
}, {
  path: "/".concat(basePre, "/advert/:type/:id?"),
  title: '新增广告',
  name: "evaluate-advert-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    refresh: true,
    activePath: "/".concat(basePre, "/advert"),
    title: '新增广告'
  }),
  component: "pages/".concat(basePre, "/evaluate/advert/edit.vue")
}];